//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysUserUpdateInfo } from '@/api/modular/system/userManage'
import store from '@/store'
import moment from 'moment'
import { mapGetters } from 'vuex'
import AvatarModal from './AvatarModal'
// mapActions
export default {
  components: {
    AvatarModal,
  },
  data() {
    return {
      // cropper
      preview: {},
      form: this.$form.createForm(this),
      sexData: [],
      option: {
        img: '/avatar2.jpg',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        // 只有自動截圖開啟 寬度高度才生效
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        // 開啟寬度和高度比例
        fixed: true,
        fixedNumber: [1, 1],
        // userInfo
        birthdayString: '',
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.initUserInfo()
  },
  methods: {
    // ...mapActions(['GetInfo']),
    /**
     * 初始化用戶信息
     */
    initUserInfo() {
      setTimeout(() => {
        this.form.setFieldsValue({
          birthday: moment(this.userInfo.birthday, 'YYYY-MM-DD'),
          nickName: this.userInfo.nickName,
          sex: this.userInfo.sex.toString(),
          email: this.userInfo.email,
          phone: this.userInfo.phone,
          tel: this.userInfo.tel,
        })
        this.birthdayString = moment(this.userInfo.birthday).format('YYYY-MM-DD')
        this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + this.userInfo.avatar
        this.getSexData()
      }, 100)
    },
    /**
     * 日期需單獨轉換
     */
    onChange(date, dateString) {
      this.birthdayString = dateString
    },
    submitUserInfo() {
      const {
        form: { validateFields },
      } = this
      validateFields((err, values) => {
        if (!err) {
          values.birthday = this.birthdayString
          values.id = this.userInfo.id
          sysUserUpdateInfo(values).then((res) => {
            if (res.success) {
              this.$message.success('個人信息更新成功')
              store.dispatch('GetInfo').then(() => {})
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    getSexData() {
      this.sexData = this.$options.filters['dictData']('sex')
    },
    setavatar(url) {
      this.option.img = process.env.VUE_APP_API_BASE_URL + '/sysFileInfo/preview?id=' + url
      store.dispatch('GetInfo').then(() => {})
    },
  },
}
