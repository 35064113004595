//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { sysFileInfoUpload } from '@/api/modular/system/fileManage'
  import { sysUserUpdateAvatar } from '@/api/modular/system/userManage'

  export default {
  data () {
    return {
      visible: false,
      id: null,
      confirmLoading: false,
      fileList: [],
      uploading: false,
      options: {
        img: '',
        autoCrop: true,
        autoCropWidth: 200,
        autoCropHeight: 200,
        fixedBox: true
      },
      previews: {}
    }
  },
  methods: {
    edit (id) {
      this.visible = true
      this.id = id
      /* 獲取原始頭像 */
    },
    close () {
      this.id = null
      this.visible = false
    },
    cancelHandel () {
      this.close()
    },
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    beforeUpload (file) {
      this.fileList = file
      const reader = new FileReader()
      // 把Array Buffer轉化為blob 如果是base64不需要
      // 轉化為base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.options.img = reader.result
      }
      // 轉化為blob
      // reader.readAsArrayBuffer(file)
      return false
    },

    // 上傳圖片（點擊上傳按鈕）
    finish (type) {
      if (type === 'blob') {
        this.uploading = true
        this.$refs.cropper.getCropBlob((data) => {
          const files = new window.File(
            [data],
            this.fileList.name,
            { type: this.fileList.type }
          )
          const formData = new FormData()
          formData.append('file', files)
          sysFileInfoUpload(formData).then((res) => {
            if (res.success) {
              this.updateAvatar(res.data)
              this.$emit('ok', res.data)
            } else {
              this.uploading = false
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$refs.cropper.getCropData((data) => {
          console.log(data)
        })
      }
    },
    updateAvatar (data) {
      const params = {
        id: this.id,
        avatar: data
      }
      sysUserUpdateAvatar(params).then((res) => {
        this.uploading = false
        if (res.success) {
          this.visible = false
          this.$message.success('頭像上傳修改成功')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    realTime (data) {
      this.previews = data
    }
  }
}
